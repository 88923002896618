.chakra-react-select__input {
  background-color: transparent !important;
}
.chakra-react-select__input:focus-visible {
  border-color: transparent !important;
  box-shadow: none !important;
}
.chakra-react-select__control{
  border-radius: 0.375rem !important;
  background: white !important;
  box-shadow: none !important;
}


.chakra-react-select__control--is-focused, .chakra-react-select__control:focus-visible, .chakra-react-select__control:focus{
  box-shadow: none !important;
  outline-offset: -1px !important;
  outline: 2px solid var(--chakra-colors-brand-base) !important;
}


body.chakra-ui-dark .chakra-react-select__control {
  background: var(--chakra-colors-neutral-800) !important;
  border: 1px solid var(--chakra-colors-neutral-700) !important;
}


.chakra-react-select__menu-portal {
  z-index: 40 !important;
}


.chakra-react-select__indicator {
  padding: 0.5rem !important;
  background-color: transparent !important;
}

.chakra-react-select__indicator.chakra-react-select__dropdown-indicator > .chakra-icon{
  width: 1.2rem !important;
  height: 1.2rem !important;
}


.chakra-react-select__indicator-separator {
  display: none !important;
}

.chakra-react-select__multi-value {
  color: var(--chakra-colors-neutral-800) !important;
  background-color: var(--chakra-colors-neutral-200) !important;
  border-radius: 1rem !important;
  font-size: 0.875rem !important;
}

.chakra-react-select__menu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 0.375rem !important;
  overflow: hidden !important;
}

.chakra-react-select__menu {
  border-radius: 0.375rem !important;
  box-shadow:0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body.chakra-ui-dark .chakra-react-select__multi-value {
  color: var(--chakra-colors-neutral-200) !important;
  background-color: var(--chakra-colors-neutral-700) !important;
}

