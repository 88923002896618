.validcheck-section {
  margin-bottom: 16px;
}

.validcheck-section h3 {
  margin-bottom: 10px;
}

.validcheck-section ul {
  margin-left: 2rem;
}

.validcheck-section > ul {
  margin-left: 0;
  margin-bottom: 0;
}

.valicheck-finding {
  padding-bottom: 1rem;
  padding-top: 1rem;
  list-style: none;
  border-bottom: 1px solid var(--chakra-colors-neutral-200);
}

.valicheck-finding:last-child {
  border-bottom: none;
}
