:root {
  --pointy: 24px
}
.home-plate {
  position: relative;
  width: 200px;                    /* Fixed width */
  background-color: var(--chakra-colors-indigo-100);       /* Light purple color */
  padding-bottom: var(--pointy);                   /* Padding for content */
  color:var(--chakra-colors-indigo-600);                     /* Text color */
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - var(--pointy)), 50% 100%, 0% calc(100% - var(--pointy)));
  border-radius: 4px;              /* Optional rounded corners on top */
}

/* Dark theme styles */
[data-theme="dark"] .home-plate {
  background-color: var(--chakra-colors-indigo-900);
  color: var(--chakra-colors-indigo-100);
}

