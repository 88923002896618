
.interactive-table tr {
  transition: all 0.3s ease;
  border-bottom: 1px solid var(--chakra-colors-neutral-100);
}
.interactive-table tr:hover {
  background: var(--chakra-colors-brandSecondary-25) !important;
  cursor: pointer;
}

.interactive-table tr  .action-button {
  visibility: hidden;
}
.interactive-table tr:hover  .action-button {
  visibility: visible;
}

body.chakra-ui-dark .interactive-table tr {
  border-bottom: 1px solid var(--chakra-colors-neutral-950);
}
body.chakra-ui-dark .interactive-table tr:hover {
  background: var(--chakra-colors-brandSecondary-950) !important;
}