@keyframes flowAnimation {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.flowing-edge {
  stroke-dasharray: 5;
  animation: flowAnimation 30s linear infinite;
}