.hex-container {
  --pointy: 10px;
  max-width: 200px;
  padding: 0 var(--pointy);                /* Adjusts the width based on the viewport */
  background-color: var(--chakra-colors-blue-100); /* Light blue color */
  color: var(--chakra-colors-blue-800);               /* Text color */
  clip-path: polygon(calc(0% + var(--pointy)) 0%, calc(100% - var(--pointy)) 0%, 100% 50%, calc(100% - var(--pointy)) 100%, calc(0% + var(--pointy)) 100%, 0% 50%);
}

.branch {
  background-color: var(--chakra-colors-blue-25);
}

/* Dark theme styles */
[data-theme="dark"] .hex-container {
  background-color: var(--chakra-colors-blue-900);
  color: var(--chakra-colors-blue-100);
}

